@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700;800;900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 958px) {
  p {
    font-size: 16px !important;
  }
}
a {
  text-decoration: none !important;
  color: #4c8df5;
}
.MuiTypography-root {
  font-family: "Montserrat", sans-serif !important;
}
.MuiTableCell-root {
  font-family: "Montserrat", sans-serif !important;
}
body {
  font-family: "Montserrat", sans-serif;
  position: relative;
  background-color: #fff;
}

.light-dark {
  color: #4f5351 !important;
}
.dark {
  color: #070908 !important;
}
.white_text {
  color: #ffffff;
}
.p-13 {
  padding-top: 13rem;
}
.fs-12 {
  font-size: 1.2rem !important;
}
.fs-14 {
  font-size: 1.4rem !important;
}
.fs-16 {
  font-size: 1.6rem !important;
}
.fs-18 {
  font-size: 1.6rem !important;
}
.fs-20 {
  font-size: 2rem !important;
}
.fs-24 {
  font-size: 2.4rem !important;
}
/* Font-Weight */
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.h100 {
  height: 100% !important;
}
.wt_color {
  color: white !important;
}
.my-1 {
  margin: 1rem auto !important;
}
.my-2 {
  margin: 2rem auto !important;
}
.p14 {
  padding: 10rem;
}
.p16 {
  padding: 16rem;
}
.px1 {
  padding: 1rem 0;
}
.p2 {
  padding: 2rem;
}
.p6 {
  padding: 6rem !important;
}
.bx-shadow {
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
}
.flex1 {
  flex: 1;
}
.jcsb {
  justify-content: space-between;
}
.vpil {
  background-color: #f0f3fd;
  color: #7489e9;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border-radius: 50px;
  font-weight: 500;
}
.blue-color {
  color: #7489e9;
}
.gpil {
  background-color: #ebfff0;
  color: #3cb05b;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border-radius: 50px;
  font-weight: 500;
}
.green-color {
  color: #3cb05b;
}
.opil {
  background-color: #fbf8ef;
  color: #fcc344;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border-radius: 50px;
  font-weight: 500;
}
.orange-color {
  color: #fcc344;
}
#closed {
  background-color: #4f5351 !important;
  color: #b2b2b2 !important;
  border: none !important;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
/* --------------product/supplier details----------------------- */
.ps_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ps_h2 {
  text-align: center;
  border: 2px solid #4c8df5;
  color: #4c8df5;
  padding: 0.5rem 0;
}
.dfcg3 {
  display: flex;
  column-gap: 3rem;
  flex-wrap: wrap;
}
/* -----------------------SupplierSearchPage---------------------- */
.ssp_pilcont {
  display: flex;
  justify-items: start;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}
.ssp_thead {
  font-size: 1.4rem;
  font-weight: 700;
  /* border-bottom: 2px solid #3cb05b; */
}
/* sidebar */

.openSidebar {
  width: 40% !important;
}
.social-icons {
  /* float: right; */
  position: absolute;
  right: 10px;
  /* top: 10px; */
}
.sidebar {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow-x: hidden;
  background-color: #4c8df5;
  transition: 0.5s all;
}
.sidebar_content {
  padding: 0 2rem;
  margin-top: 10rem;
}
.sidebar_ul {
  list-style: none;
}
.sidebar_link {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.sidebar_link:not(:last-child) {
  margin-bottom: 5rem;
}
.sidebar_icon {
  font-size: 3rem;
  color: #fff;
  padding-right: 1rem;
}
.overlay {
  z-index: 999;
  position: fixed;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 0.2s;
}
.sidebar_container {
  padding: 2rem 0rem;
}
.sidebar_close {
  cursor: pointer;
  margin-left: 10px;
}
.land_btn_cont {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
.fa-circle-xmark {
  color: #fff !important;
  position: absolute;
  font-size: 3.3rem;
}

ul {
  list-style: none;
}
input::file-selector-button {
  font-weight: bold;
  color: #4c8df5;
  border: thin solid #4c8df5;
  border-radius: 3px;
  content: "Upload File";
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0 1rem 0 1rem;
}

/* navbar */
.navbar {
  width: 100%;
  padding: 1rem;
  position: fixed;
  transition: 0.8s all;
  z-index: 99;
  background-color: #070908;
}

.navigation_menu {
  display: none;
  cursor: pointer;
  transition: all 0.5s;
}
.navigation_menu > div {
  content: " ";
  display: block;
  width: 4rem;
  height: 0.2rem;
  background-color: #4c8df5 !important;
  position: relative;
}
.nav_slect {
  border: 1px solid #4c8df5;
  color: #4c8df5;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8%;
  position: relative;
}
.nav-select_show {
  position: absolute;
  top: 6rem;
  right: 2rem;
  border-radius: 4px;
}
.navul {
  background-color: #070909;
  color: #4c8df5;
  font-size: 1.6rem;
  padding: 2rem 1rem;
}
.navul li {
  padding: 1rem;
  width: fit-content;
}
.navul li:hover {
  border-bottom: 1px solid #4c8df5;
}
/* DropDown */
.dropbtn {
  background: #070908;
  color: #4c8df5;
  border: 1px solid #4c8df5;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 0 1.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.backgroundColor {
  background-color: #ebebeb !important;
}
.dropdown-content {
  display: none;
  position: absolute;
  background: #070908;
  /* width: 80%; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #4c8df5;
  padding: 1.2rem 1.6rem;
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 1.6rem;
}

.dropdown-content a:hover {
  border-bottom: 1px solid #4c8df5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: black;
}
/* -----------------search page for PharmaDigm */

.ph-spcardmain {
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  margin: 0rem 1rem 2rem 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  border: 0.2rem solid #ffffff;
}
.ph-spcardmain:hover {
  border: 0.2rem solid #4c8df5;
}
.ph-spcardmain2 {
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  margin: 0rem 0rem 2rem 1rem;
  padding: 2rem 1.5rem;
  border-radius: 8px;
}
.ph-cardsphead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.ph-cardspbody {
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.ph-cardspp {
  font-size: 1.3rem;
  padding: 0.2rem 0;
  line-height: 2rem;
  margin-bottom: 0.5rem !important;
  text-align: start;
}
.about-molecule {
  font-size: 11px !important;
  line-height: 18px !important;
}
.offer-font {
  font-size: 1.2rem;
  padding: 0.2rem 0;
}
/* ------------------------contactus--------------------- */
.ph-csmain {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5rem 0;
}

/* -------------------------- */

.navigation_menu > div:after {
  content: " ";
  display: block;
  width: 4rem;
  height: 0.2rem;
  background-color: #4c8df5 !important;
  position: absolute;
  top: 8px;
  right: 0;
  left: 0 !important;
}
.navigation_menu > div:before {
  content: " ";
  display: block;
  width: 4rem;
  height: 0.2rem;
  background-color: #4c8df5 !important;
  position: absolute;
  bottom: 8px;
  left: 0;
}
/* .naigation_menu > div:nth-child(2){
  width: 4rem;
  height: .2rem;
  background-color: #1BB980;
  margin: .8rem 0;
  transition: all .5s;
} 
.naigation_menu > div:nth-child(1){
  width: 4rem;
  height: .2rem;
  background-color: #1BB980;
  transition: all .5s;
} 
.naigation_menu > div:nth-child(3){
  width: 4rem;
  height: .2rem;
  background-color: #1BB980;
  transition: all .5s;
}  */

.navbar_header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .brand-logo{
  width: 13.5rem;
  height: 5.1rem
}   */
.d-flex {
  display: flex !important;
  align-items: center;
  text-align: center;
}
.d-Flex {
  display: flex;
  flex-wrap: wrap;
}
.justify-between {
  justify-content: space-around;
}
.only-d-flex {
  display: flex !important;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center !important;
}
.text-start {
  text-align: start;
}
.navbar_logo {
  font-size: 2.3rem;
  font-weight: 600;
  color: #ffffff;
  padding-left: 5px;
  display: inline-block;
}
.navigation_list {
  display: flex;
  list-style: none;
}

.navigation_list--link {
  color: #4c8df5;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
.navigation_list--link:hover {
  color: white;
}

.navigation_list--link:not(:last-child) {
  margin-right: 3rem;
}

/* footer */

.footer {
  border-top: 2px solid #fcfcfc;
  background-color: #070908;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer_container {
  margin: 0 auto;
  width: 90%;
  padding: 2rem;
}
.footer_links {
  display: flex;
  justify-content: space-between;
}

.footer_section--1 {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}
.footer_section--2 {
  padding: 2rem 0;
}
.footer_content:not(:last-child) {
  margin-right: 5rem;
}
.footer_ul {
  padding-top: 1.3rem;
}
.footer_li:not(:last-child) {
  margin-bottom: 1rem;
}
.footer_text {
  color: #fcfcfc;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  margin-left: 5rem;
}
.footer_socialIcons {
  display: flex;
  margin: 2rem 0;
}
.content_heading {
  color: #fcfcfc;
  font-weight: 700;
  font-size: 2rem;
  margin-left: 3rem;
}

.footer_li {
  color: #b2b2b2;
  font-weight: 400;
  font-size: 1.5rem;
  width: fit-content;
  cursor: pointer;
}
.copy {
  color: #fcfcfc;
  font-size: 1.2rem;
}

.fa-brands {
  font-size: 2rem;
  color: #4c8df5;
}
.fa-brands:not(:last-child) {
  margin-right: 1.3rem;
}

.footer_section--2 {
  border: 2px solid #fcfcfc;
  border-left: none;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refer {
  color: #b2b2b2;
  font-weight: 600;
  margin-right: 2rem;
  font-size: 3rem;
}
.footer_section--3 {
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.terms {
  display: flex;
}

.terms_li {
  font-size: 1.5rem;
  font-weight: 400;
  color: #b2b2b2;
}
.terms_li:not(:last-child) {
  margin-right: 2rem;
}
.language {
  font-size: 1.5rem;
  font-weight: 400;
  color: #b2b2b2;
}

/* home page components */

.main {
  padding: 12rem 8rem 6rem 8rem;
  background-color: #070908;
}
.about-main {
  padding: 7rem 0rem 6rem 0rem;
  background-color: #070908;
}
.main_btn {
  /* position: absolute; */
  text-align: end;
  margin-top: 2rem;
}
#img4 {
  width: 90%;
  margin: 0 auto;
  margin-top: 2.5rem;
}
#img5 {
  width: 90%;
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-left: 2rem;
}
.main_textcenter {
  width: 80%;
  margin: 0 auto;
  margin-top: 2.5rem;
}
.mainImg {
  width: 100%;
}
.main_imgcont2 {
  text-align: center !important;
}
.main_img1 {
  width: 100%;
}
.main_container {
  display: flex;
  margin: auto;
  align-items: start;
  gap: 4rem;
}
.main_text {
  width: 90%;
  margin: 3.5rem 0;
}
.main_tiles {
  width: 40%;
}
.main_tiles a {
  display: block;
}
.main_tiles a:not(:last-child) {
  margin-bottom: 2rem;
}
.main_content {
  width: 60%;
  text-align: start;
  transition: all 2s;
  padding-left: 1.5rem;
  position: relative;
}
.primaryText {
  font-size: 5rem;
  font-weight: 850;
  color: #fcfcfc;
}
.side_tile {
  display: flex;
  align-items: center;
  background-color: #4f5351;
  border-radius: 0.8rem;
  padding: 3rem 2rem;
  gap: 1rem;
  border: 2.1px solid #4f5351;
  height: 10rem;
  transition: all 0.2s;
  width: 85%;
  margin: 0 auto;
}
.side_tile:not(:last-child) {
  margin-bottom: 1.7rem;
}
.sideactive {
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #4f5351;
  border-radius: 0.8rem;
  padding: 3rem 2rem;
  gap: 1rem;
  border: 2.1px solid #4c8df5;
  height: 10rem;
  transition: all 0.2s;
}
.sideactive:not(:last-child) {
  margin-bottom: 1.7rem;
}
.main_h1 {
  font-weight: 900;
  font-size: 5rem;
  line-height: 6.4rem;
  color: #fcfcfc;
  margin-top: 1rem;
}

.side_tile:hover {
  border: 2.1px solid #4c8df5;
  border-radius: 0.8rem;
  padding: 3rem 2rem;
  gap: 1rem;
  /* margin-bottom: 1.7rem; */
  height: 10rem;
}
.side_tile:not(:last-child):hover {
  margin-bottom: 1.7rem;
}
.side_tile:not(:last-child) {
  margin-bottom: 1.7rem;
}
.tile_image {
  margin-right: 1rem;
}

.secondaryText {
  font-weight: 400;
  font-size: 1.6rem;
  color: #f5f5f5;
  line-height: 3rem;
  /* margin-top: 3rem; */
}

.tile_primary {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #f5f5f5;
}
.tile_secondary {
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #f5f5f5;
}
.main_banner {
  background-size: cover;
  background-position: center;
  width: 80%;
  margin: 5rem auto;
  height: 20rem;
  position: relative;
}

.bannerImage {
  width: 80%;
  margin: 0 auto;
}
.tile_img {
  width: 10rem !important;
  height: auto;
  margin: 5px auto;
}
.main_pentagons {
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.pentagon {
  width: 25rem;
  height: 25rem;
}
.pentagon:not(:last-child) {
  margin-right: 2rem;
}
.pentagon_img {
  width: 100%;
}

/* counts styling */

.counts {
  background-color: #fff;
  padding: 3rem 0;
}

.counts_container {
  display: flex;
  margin: 0 auto;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.counts_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counts_data {
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 4rem;
}
.counts_title {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  text-align: center;
}

.story {
  /* padding: 10rem 0; */
  margin: 2rem 0;
  background-color: #070908;
  background-image: linear-gradient(to right, transparent, transparent),
    url(/src/assets/images/img/home18.PNG);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.story_container {
  display: flex;
  margin: 0 auto;
  width: 80%;
  padding: 8rem 0;
}
.story_header {
  margin: 0 auto;
  width: 80%;
}
.story_content {
  width: 50%;
  padding: 3rem 0;
}
/* .story_p {
  padding: 8rem;
} */
.story_img {
  width: 50%;
}
.story_img1 {
  width: 80%;
  /* height: 45rem; */
}
.story_txtal {
  text-align: end;
}
.story_heading {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #fcfcfc;
}
.story_text {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #fcfcfc;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
/* .storyImage{
  margin-left: auto;
} */

/* brands */

.brands {
  background-color: #fff;
  padding: 2.5rem 0;
  margin: 2rem 0;
}
.brands_container {
  margin: 0 auto;
  width: 80%;
}
.brands_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.brands_primary {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
.brand_icon {
  width: 10rem;
  height: 10rem;
  text-align: center;
}
.brands_icons {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}
.brand_name {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
/* .brand_mr{
  margin-top: 1rem;
} */

/* investors stories */

.review {
  padding: 8rem 0;
  background-color: #070908;
}
/* .ic {
  display: flex;
  position: relative;
} */
.ic_cont1 {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  gap: 1.5rem;
}
.ic_image {
  width: 10rem;
  height: 10rem;
}
.ic_cont2 {
  display: flex;
  gap: 1rem;
  width: 10%;
  height: 10rem;
  overflow: hidden;
  transition: width 1s;
  transition-timing-function: cubic-bezier(0.38, 0.4, 0.62, 0.9);
}
.ic_p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.ic_fw {
  font-weight: 600;
}
.ic_cont2:hover {
  width: 40%;
  height: 10rem;
  background-color: #1170c8;
  color: #fcfcfc;
  flex-wrap: nowrap;
  /* padding: 0.3rem; */
}

.review_container {
  margin: 0 auto;
  width: 80%;
}
.review_primary {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #fcfcfc;
}
.reviewmr {
  margin: 3rem 0;
}
.review_img {
  width: 100%;
}
.profile_img {
  width: 20rem;
  height: auto;
}
.comment {
  padding: 5rem 0;
  background-color: #fff;
}
.comments {
  margin-top: 5rem;
  display: flex;
  align-items: center;
}

.comment_container {
  margin: 0 auto;
  width: 80%;
}
.comment_user {
  margin-right: 2rem;
}

.comment--1 {
  width: 50%;
  display: flex;
  align-items: center;
}
.comments-cont {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}
.comment--2 {
  width: 50%;
  display: flex;
  align-items: center;
}
.comment_primary {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
}
.comment_secondary {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-top: 2rem;
}

.ss_main {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
}
.ss_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.ss_f2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 1rem 0;
}
.ss_f3 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  margin-top: 2rem;
}

/* faq */
.faq_color {
  color: #070908 !important;
}
.faq {
  padding: 10rem 0;
  margin: 4rem 0 0 0;
  background-color: #070908;
}

.faq_container {
  margin: 0 auto;
  width: 80%;
}
.faq_primary {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #fcfcfc;
}
.question_container--1 {
  display: flex;
  margin-top: 2rem;
}
.question_container--2 {
  display: flex;
  margin-top: 5rem;
}
.question {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #f2f2f2;
  margin-bottom: 2rem;
}
.question2 {
  width: 50%;
  margin-left: 4rem;
}
.question1 {
  width: 50%;
}
.answer {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #f2f2f2;
}
.news {
  background-color: #fff;
  padding: 4rem 0;
  margin: 2rem 0;
}
.news_thumbnails {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.thumbnails {
  display: flex;
  flex-direction: column;
  width: 90% !important;
  justify-content: center;
  margin: auto;
  gap: 2rem;
  /* z-index: -1 !important; */
}
/* .thumbnails:not(:last-child){
  margin-right: 2rem;
} */
.news_title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #ffffff;
  margin: 0 1.5rem;
  /* padding: 0 2rem; */
}

.news_container {
  margin: 0 auto;
  width: 80%;
}
.news_heading {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  margin-bottom: 2rem;
}

.bottomBanner {
  padding: 10rem 0;
  margin-top: 4rem;
  background-color: #070908;
}

.bottomBanner_container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: start;
}
.bottomBanner_content {
  width: 50%;
}
.bottom_imgcont {
  width: 50%;
  display: flex;
  justify-content: end;
}
.bottom_img {
  width: 65%;
}

.bottomBanner_primary {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #fcfcfc;
}

.bottomBanner_secondary {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #fcfcfc;
  margin: 4rem 0;
}
.btn_grp {
  margin: 10rem auto;
  /* width: 50%; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.bottom_btn--1 {
  margin-right: 2rem;
}

.btn {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: #4c8df5 !important;
  color: #fcfcfc !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.4rem !important;
  padding: 0.95rem 2.4rem !important;
  font-weight: 600 !important;
  border: 2px solid #4c8df5 !important;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 0.5rem !important;
}

.active-btn {
  font-family: "Montserrat" !important;
  font-style: normal !important;

  border-radius: 4px;
  text-align: center;
  font-size: 1.4rem !important;
  padding: 0.95rem 2.4rem !important;
  font-weight: 600 !important;

  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  border: 2px solid #4c8df5 !important;
  background-color: #ffffff !important;
  color: #4c8df5 !important;
}
/* .btn-padding {
  padding: 1.5rem !important;
} */
.btn:hover {
  background-color: #4c8df5 !important ;
  border: 2px solid #4c8df5 !important;
  transition: 1s;
}
.btn-contact:hover {
  opacity: 0.55;
}

.btn-contact {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: #7915cf !important;
  color: #fcfcfc !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.4rem !important;
  padding: 0.95rem 2.4rem !important;
  font-weight: 600 !important;
  border: 2px solid #7915cf !important;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 0.5rem !important;
}
.enquire-btn {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: #4c8df5 !important;
  color: #fcfcfc !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.6rem !important;
  padding: 0.2rem 1rem !important;
  font-weight: 600 !important;
  border: 2px solid #4c8df5 !important;
  font-size: 1.4rem;
  line-height: 3rem;
  text-transform: none !important;
}
.enquire-btn:hover {
  background-color: #4c8df5 !important ;
  border: 2px solid #4c8df5 !important;
  transition: 1s;
}
.borderbtn {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  border: 2px solid #4c8df5 !important;
  background-color: #070908;
  color: #4c8df5 !important;
  font-size: 1.6rem !important;
  padding: 0.2rem 1rem !important;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 4px;
  font-weight: 600 !important;
}
.whitebtn {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  border: 2px solid #4c8df5 !important;
  background-color: #fff !important ;
  color: #4c8df5 !important;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 4px;
  font-weight: 600 !important;
}
.greenBorder {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  border: 2px solid #4c8df5 !important;
  background-color: #fff;
  color: #4c8df5 !important;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 4px;
  font-weight: 600 !important;
}
.btnsecondary {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  border: 2px solid#f5f5f5 !important;
  background-color: #f5f5f5;
  color: #b2b2b2 !important;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  line-height: 3rem;
  text-transform: none !important;
  border-radius: 4px;
  font-weight: 600 !important;
}
.btngrey {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: #4f5351 !important;
  color: #b2b2b2 !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  font-weight: 600 !important;
  border: 2px solid #4f5351 !important;
  font-size: 1.6rem;
  line-height: 3rem;
  text-transform: none !important;
}
.btnred {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: #c65353 !important;
  color: #fff !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  font-weight: 600 !important;
  border: 2px solid #c65353 !important;
  line-height: 3rem;
  text-transform: none !important;
}
.borderbtn:hover {
  background-color: #4c8df5 !important;
  color: #070908 !important ;
  transition: 1s;
}
.btnblack {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  background-color: black !important;
  color: #fff !important;
  border-radius: 4px;
  text-align: center;
  font-size: 1.6rem !important;
  padding: 0.4rem 2.4rem !important;
  font-weight: 600 !important;
  border: 2px solid black !important;
  line-height: 3rem;
  text-transform: none !important;
}
/* ----------Conact us --------------------------*/

.ncs_card {
  width: 25%; /* Adjust size as needed */
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
  position: relative;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all ease 1s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ncs_card:hover {
  background-color: #dcdcdc;
  transition: 0.5s;
  opacity: 0.8 !important;
}
.ncs_circle {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for the circle */
  text-align: center;
  font-size: 3rem;
  padding: 1rem;
  background-color: #ffffff;
}

.ncs_content {
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 7rem auto 1rem auto;
  min-height: 5rem;
}
/* LoginPage */

.login_main {
  padding-top: 7rem;
  text-align: center;
  min-height: 54vh;
}
.login_heading {
  font-weight: 800;
  font-size: 3rem;
  line-height: 6rem;
  color: #070908;
  margin-top: 1.5rem;
}
.login_body {
  margin: auto;
  width: 30rem;
}
.reg_body {
  margin: auto;
  width: 40rem;
}
.login_button {
  font-weight: 400;
  font-size: 1.6rem !important;
  line-height: 2.4 rem !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
}
.login_divider {
  font-size: 1.6rem;
  padding: 2rem 0rem 2rem 0rem;
}
.login_para {
  /* width: 100%; */
  text-align: start;
  font-size: 1.4rem;
  /* padding-bottom: 1rem; */
  line-height: 2rem;
  margin-top: 1rem;
}
.login_input {
  width: 96%;
  font-weight: 400;
  font-size: 1.6rem;
  border-radius: 4px;
  outline: none;
  background-color: white;
  border: 1px solid #4c8df5;
  padding: 1rem 1rem;
  /* padding-left: 0.5rem; */
}

.login_input::placeholder {
  padding-left: 0.6rem !important;
}

.login_link {
  color: #4c8df5 !important;
}
.login_last_para {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 2rem 0 2rem 0;
}

/* Verfiy Email */
.verify_main {
  min-height: 70vh;
  text-align: center;
  padding: 10rem;
  /* max-width: 50rem; */
  margin: auto;
}
.verfiyImg {
  width: 20rem;
  height: 20rem;
  margin-top: 5rem;
}
.verify_heading {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8 rem;
  padding: 2rem 0 2rem 0;
}
.verfiy_p1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin-bottom: 3rem;
}
.verfiy_link {
  color: #4c8df5 !important;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.2rem;
}
.css-1ws8119-MuiDivider-root::before {
  position: static !important;
}
.css-1ws8119-MuiDivider-root::after {
  position: static !important;
}
.everifylink {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
/* signupready */
.signupready_main {
  padding-top: 10rem;
  text-align: center;
  margin: auto;
}
.signupready_p1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
  margin-top: 5rem;
}
.signupready_p2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding: 1rem 0 2rem 0;
}
.signupready_cont1 {
  margin: auto;
  text-align: start;
  max-width: 40rem;
}
.signupready_p3 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.signupready_p4 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.signup-input {
  margin: 2rem 0 2rem 0;
  color: #b2b2b2 !important;
}
.signup-input::placeholder {
  color: #b2b2b2 !important;
}
.signupready_p5 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}

/* SignupofFounders */

.signupoffounders_main {
  padding-top: 8rem;
  margin: auto;
  min-height: 80vh;
  max-width: 80%;
}
.welcome_main {
  padding-top: 12rem;
  margin: auto;
  min-height: 80vh;
  max-width: 100%;
}
/* SignupofFounders End  here */

/* goals */
.goal_head {
  text-align: center;
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
}
.goal_p1 {
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding: "8rem 0 8rem 0";
}
.goal_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 6rem;
  row-gap: 3rem;
}
.goals_cont2 {
  position: relative;
  box-shadow: 3px 3px 11px rgba(63, 63, 63, 0.15);
  border-radius: 4px;
  background: #f5f5f5;
  padding: 2.4rem;
  width: 41.5%; /* Adjust size as needed */
  height: 20rem;
  transition: background 1s;
  /* overflow: hidden !important; */
  border: 0.2rem solid #f5f5f5;
}
.golesImg {
  width: 40%;
  margin-left: -85px;
}

.goals_cont2:hover {
  background-color: #fcfcfc;
  border: 0.2rem solid #9bbfda;
}
.goal_title {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 2rem;
}
.goal_title1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 1rem;
}
.goal_p1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  padding: 2rem 0rem 4rem 0;
}
.goal_p2 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 2rem 0rem 2rem 0;
}
/* interest */
.interest_main {
  text-align: center;
  padding: 5rem;
  max-width: 80rem;
  margin: auto;
}
.interest_head {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
}
.interest_list {
  padding: 2rem;
}
/* Risks */
.risk_main {
  padding: 2rem;
  margin-top: 5rem;
  text-align: center;
}
.risk_head {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
}
.risk_cont {
  max-width: 40rem;
  margin: auto;
  text-align: start;
}
.risk_label {
  font-weight: 400;
  font-size: 1.6rem !important;
  line-height: 3rem;
}
.risk_btn {
  text-align: center;
  width: auto;
  margin-top: 2rem;
}

/* Verification */
.stepper {
  margin: auto;
  margin-top: 3rem;
  max-width: 20rem;
}
.css-1bw0nnu-MuiStep-root {
  padding: 0 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #4c8df5 !important;
  font-size: 3rem;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #4c8df5 !important;
  font-size: 3rem;
}
.css-z7uhs0-MuiStepConnector-line {
  color: #4c8df5 !important;
  border-color: #4c8df5 !important;
}
/* .css-z7uhs0-MuiStepConnector-line.Mui-completed {
  color:#4c8df5 !important
} */
.css-vnkopk-MuiStepLabel-iconContainer {
  padding: 0 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 3rem !important;
}
.css-117w1su-MuiStepIcon-text {
  font-size: 1rem !important;
}
.stepButton {
  font-size: 5rem !important;
}
.step0_main {
  text-align: center;
  padding-top: 3rem;
  min-height: 80vh;
}
.step0_p1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
}
.step0_p2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding: 1rem 0rem 2rem 0rem;
}
.step0_p3 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  padding: 0rem 0rem 2rem 0rem;
}
.step0_cont1 {
  text-align: start;
  margin: auto;
  max-width: 40rem;
}
.step0_h4 {
  font-weight: 600;
  font-size: 2.04rem;
  line-height: 2.04rem;
  padding: 1rem 0 0.5rem 0;
}
.step0_p4 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 3rem;
}
.step0_cont3 {
  padding: 1.5rem 0 2rem 0;
  margin-top: 2rem !important;
}
.step0_p5 {
  font-weight: 400;
  font-size: 1.21rem;
  padding: 0rem 0 0.5rem 0;
  line-height: 1.06rem;
}
.setp_completed {
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 6rem;
  margin-top: 8rem;
}
.completed_img {
  width: 60rem;
  height: 60rem;
  margin-bottom: 8rem;
}

/* Sidebar */
.sidebar_main {
  width: 20%;
  padding-top: 10rem;
  background: #f5f5f5;
  text-align: center;
  font-size: 1.6rem;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
.admin_sidebar {
  width: 100% !important;
  background-color: #070908 !important;
}
.sidebarNav_item {
  padding: 1rem 7rem !important;
  text-align: start;
  text-decoration: none;
  padding: 1rem 1rem 1rem 6rem !important;
}
.sidebarNav_item:hover {
  border-right: 2px solid #4c8df5;
  background-color: #fcfcfc;
  padding: 1rem 1rem 1rem 6rem !important;
}

.active {
  border-right: 2px solid #4c8df5;
  background-color: #fcfcfc;
  padding: 1rem 1rem 1rem 6rem !important;
  text-align: start;
}
.sidebar_cont1 {
  width: 100%;
  padding: 8rem 0;
}
.nav_wd {
  width: 100%;
}
.nav_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Company Profile Founders */
.UploadBtn {
  outline: none;
  border: none;
}
.cfp_mr {
  margin: 0 0 1.5rem 0;
}
.cfp_main {
  display: flex;
  flex-direction: row;
}
.cfp_wd {
  width: 70%;
}
.cfp_wd1 {
  width: 80%;
}

.cfp_mr2 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 1.5rem 0 1rem 0;
}
.cfp_mr1 {
  margin: 4rem 0 0.1rem 0;
}
.cfp_h4 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.cfp_p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 1rem 0 1rem 0;
}
.cfp_cont {
  padding: 2rem 0 2rem 0rem;
  width: 76rem;
}
.cfp_btn_cont {
  text-align: end !important;
  padding: 2rem;
  gap: 2rem;
}
.cfp_footer {
  text-align: center;
  margin: 5rem;
}

/* MarketPlace activity */
.market_h4 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  padding: 2rem 0 2rem 0;
}
.market_mr {
  padding: 0 !important;
  margin: 4rem 0 0.5rem 0;
}
.market_p {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  padding: 2rem 0 4rem 0;
}
.market_cont1 {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.market_p1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #4f5351;
}
.market_p2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #4f5351;
}
.markettable_font {
  font-weight: 400;
  font-size: 1.6rem !important;
  line-height: 2.4rem !important  ;
}
.markettable_head {
  font-weight: 600 !important;
  font-size: 1.6rem !important;
  line-height: 2.4rem !important  ;
}
.prequalend {
  padding: 15rem;
  text-align: center;
}

.posttablecell1 {
  display: flex;
  align-items: start;
  text-align: start;
  gap: 1rem;
  font-weight: 400;
  width: 75% !important;
}
/* .css-1ex1afd-MuiTableCell-root{
  width: 45%;
} */
.postbtncont {
  text-align: end;
  padding-top: 2rem;
}
/* settings */
.settings_cont {
  max-width: 50%;
}
.setting_btncont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.settings_h4 {
  margin: 3rem 0 1rem 0;
}

/* Refer and Earn */
.refer_cont {
  background-color: #f5f5f5;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  padding: 5rem;
  margin: 4rem 0 4rem 0;
  display: flex;
  align-items: flex-start;
  gap: 5rem;
}

.ref_cont {
  padding: 2rem;
}
.refer_cont4 {
  /* flex-grow: 1; */
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  flex: auto;
  /* max-width: 37%; */
  width: 100%;

  padding: 2rem;
}
.refer_cont8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}
.refer_p1 {
  font-size: 2em;
  font-weight: 600;
  padding: 2rem 0 2rem 0;
}

.refer_input {
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}
.refer_btn-grp {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: auto;
  margin-top: 2rem;
  gap: 2rem;
}
.refer_number_icon {
  border: 1px solid grey;
  border-radius: 50%;
  text-align: center;
  min-width: 2rem;
  padding: 0.5rem;
  font-size: 1.4rem;
}
.refer_cont5 {
  display: flex;
  align-items: start;
  margin: 1rem 0 1rem 0;
  gap: 1rem;
}

/* watchList tab researchOffer */
.reoff_h4 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 3rem 0 3rem 0;
}
.wtr {
  padding: 8rem;
  flex: 1 1;
}
.reoffcard_main {
  background-color: #fcfcfc;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  /* width: 30.7rem; */
}
.reoffcard_main_container {
  padding: 2rem;
}
.prequal_mr {
  margin: 3rem 0 0.5rem 0 !important;
  padding: 0 !important;
}
.reoffcard_p1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  padding: 2rem 0 2rem 0;
}
.reoffcard_p2 {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.reoffcard_cont3 {
  background-color: #f5f5f5;
  padding: 1rem;
}
.reoffcard_cont2 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0 2rem 0;
}
.reffcard_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.reoffcard_cont6 {
  background-color: #e7e7e7;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
}
.reoff_cont7 {
  text-align: center;
  margin: 5rem 0 5rem 0;
}
.reoff_cont8 {
  text-align: center;
  padding: 1rem;
  background: #e7e7e7;
  border-radius: 0px 0px 5px 5px;
  font-weight: 400;
  font-size: 1.2rem;
}

/* search page */

.search {
  padding-top: 12rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.search_container {
  margin: 0 auto;
  width: 80%;
}

.tabContent {
  margin: 3rem 0;
}
.tab_heading {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
}
.tab_subHeading {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 2rem 0;
}

.search-filter {
  margin: 2rem 0;
}
.filter {
  display: flex;
}
.searchInput {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.filter div:not(:last-child) {
  margin-right: 2rem;
}

.searchFilter {
  width: 20%;
}
.options {
  display: flex;
  flex-wrap: wrap;
}
.selected_options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.selected {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  border: 1px solid black;
  padding: 0.2rem 1rem;
  border-radius: 20px;
}
.selected:not(:last-child) {
  margin-right: 2rem;
}
.selected_label {
  margin-right: 1rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.fa-close {
  cursor: pointer;
  transition: all 0.2s;
}
.fa-close:hover {
  color: #eb4b56;
}

.clearAll {
  margin-left: 2rem;
  cursor: pointer;
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.18);
  padding: 0.2rem 1rem;
  border-radius: 20px;
}

.clearAll_label {
  color: rgba(0, 0, 0, 0.18);
  margin-right: 1rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}

.cards_investment {
  cursor: pointer;
  border-radius: 8px;
  background-color: #f5f5f5;
  overflow: hidden;
  width: 30%;
  margin-top: 2rem;
  margin-right: 0.6rem;
  position: relative;
  /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%);; */
}
.cards_content {
  padding: 2rem;
  margin-bottom: 2rem;
}

.investmentCard_bottom {
  background-color: #e7e7e7;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 2rem;
}
.seeking {
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 3rem;
}

.search_results {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card_img {
  width: 100%;
}
.card_logo--comp {
  margin-left: 1rem;
  transform: translateY(-3rem);
}
.card_primary {
  font-weight: 600;
  font-size: 2.4rem;
}
.card_secondary {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.card_mr {
  min-height: 8rem;
}
.tag_label {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* margin-top: 1rem; */
}
.tag {
  display: inline-block;
  border-radius: 4px;
  background-color: #fff;
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;
  margin-top: 1rem;
}
.tag:not(:last-child) {
  margin-right: 1rem;
}

.progressBar_container {
  margin: 2rem 0;
}
.progressData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.progressBar {
  /* -webkit-appearance: none; */
  width: 100%;
  height: 1.6rem;
  font-size: 2.8rem;
  overflow: hidden;
}

.progressBar::-webkit-progress-bar {
  background-color: #4c8df5;
  border-radius: 30px;
  overflow: hidden;
}
.final_data_container {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
}
.final_data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.final_data--heading {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.final_data--sub {
  font-weight: 700;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
/* Pre campaign creation Fund raising */
.precampbtncont {
  background-color: #f5f5f5;
  border-top: 1px solid #fcfcfc;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: end;
  text-align: end;
  gap: 2rem;
  padding: 2rem 8rem 2rem 0;
}
.precampbtncont1 {
  text-align: end;
  padding: 2rem 0rem 2rem 0;
}
.font_p1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.font_p2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.inputasfile {
  content: "Select some files";
  padding: 2rem;
  background-color: #b2b2b2;
}
/* Fund rasing Faq */
.precampfaq_cont {
  display: flex;
  padding: rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}
.precampfaq_cont1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  margin: 1rem 0 1rem 0;
}
.precampfaq_textarea {
  width: 95%;
  resize: none;
  height: 8rem;
  outline: none;
  background-color: #f5f5f5;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin: 1rem 0 3rem 0;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1rem;
}
.final_p1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
/* PreQualifying Application */
.prequaapp_cont1 {
  display: flex;
  text-align: start;
  align-items: start;
  justify-content: center;
  gap: 2rem;
}
.prequaapp_cont2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prequaapp_p1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 2rem 0;
}
.prequaapp_main {
  text-align: center;
  padding: 13rem 22rem 13rem 22rem;
  max-width: auto;
}
.prequaapp_card {
  padding: 2rem;
  background: #f5f5f5;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 40%;
}
.prequaapp_list {
  padding: "2rem";
  list-style: disc;
}

.threeGrid {
  background-color: #070908;
}
.threeGrid_container {
  margin: 0 auto;
  width: 80%;
  padding: 10rem 0;
}
.threeGrid_row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 2rem;
}

.threeGrid_row:not(:last-child) {
  margin-bottom: 2rem;
}
.threeGrid_col {
  width: 35%;
}
.threeGrid_col:not(:last-child) {
  margin-right: 2.5rem;
}
.title_h1 {
  color: #fff;
  font-weight: 600;
  line-height: 3rem;
  font-size: 2.4rem;
  margin: 2rem 0 1rem 0;
}
.threeGrid_p {
  color: #fff;
  font-weight: 400;
  line-height: 3.2rem;
  font-size: 2rem;
}
.title {
  color: #fff;
  font-weight: 700;
  line-height: 3.2rem;
  font-size: 3.2rem;
}

.sub_text {
  margin-top: 2rem;
  color: #fff;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.8rem;
}
.showCards {
  margin: 6rem 0;
}
.osc {
  border: 4px solid #e7e7e7;

  filter: drop-shadow(0px 5px 9px rgba(193, 205, 210, 0.25));
  border-radius: 4px;
  width: 30%;
  padding: 1rem;
}
.osx_con15 {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  width: 30%;
}
.osc_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.osc_f2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.osc_list {
  list-style-type: disc !important;
  color: #fff;
  margin-left: 3rem;
}

.osc_card {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
}
.showCards_container {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.primary_title {
  font-weight: 800;
  line-height: 4rem;
  font-size: 3.5rem;
}
.primary_subTitle {
  margin: 2.5rem 0;
  line-height: 3.2rem;
  font-weight: 400;
  font-size: 2rem;
}
.cards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 4rem 2rem;
  border-radius: 8px;
  width: 28%;
  margin-right: 1rem;
  margin-top: 2.5rem;
  background-color: #f5f5f5;
  cursor: pointer;
  border: 0.2rem solid #f5f5f5;
  color: white !important;
}
.card:hover {
  cursor: pointer;
  /* color: white; */
  background-color: #ffffff;
  border: 0.2rem solid #9bbfda;
}
.card:hover .login_input {
  background-color: #f5f5f5;
}
.card_header {
  display: flex;
  align-items: center;
}
.card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}
.card_imgcont {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card_heading {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin: 3rem 0;
}
.card_text {
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 400;
}
.card_cont1 {
  display: flex;
  align-items: flex-start;

  flex-direction: column;
  text-align: start;
  gap: 2rem;
}
.card_font1 {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.card_btnmr {
  margin: 2rem 0;
}

.card_cont2 {
  display: flex;
  align-items: start;
  /* justify-content: space-between; */
  gap: 8rem;
  margin-top: 1rem;
}
.donation_cards {
  padding: 8rem 0;
  background-color: #070908;
}

.donation_cards_container {
  width: 80%;
  margin: 0 auto;
}

.noBoxShadow {
  box-shadow: none !important;
}
.radiobtn {
  font-size: 1.6rem;
  color: #4c8df5;
}
.form-control {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rrems;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-bottom: 1rem;
  accent-color: #4c8df5;
}
.preQualend {
  width: 80%;
  margin: auto;
}

/*  main Campegain deal term */
.mcdt_p1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  padding: 1rem 0 0.5rem 0;
}
.mcdt_p2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 0.5rem 0 0.5rem 0;
}
.mcdt_p3 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 3rem;
}
.mcdt_cont {
  margin: 2.5rem 0 2.5rem 0;
}
.mcp_input {
  margin: 1rem 0 1rem 0;
}
.mcp_cont {
  margin: 2rem 0 2rem 0;
}

.mcb_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
#landing_primary {
  width: 80% !important;
  margin: 0 auto !important;
}
.mcb_btn_cont {
  margin: 1rem 0;
}
.mcb_cont {
  display: flex;
  gap: 4rem;
}
.mcb_cont1 {
  width: 15%;
}
.mcb_cont2 {
  width: 25%;
}
.mcb_cont3 {
  width: 55%;
}
.mcb_cont4 {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  background-color: #f5f5f5;
  gap: 2rem;
  border-radius: 8px;
}
.mcb_inp {
  margin-top: 1rem !important;
  font-size: 2rem !important;
}
/* Restricted */
.restrict_main {
  text-align: center;
  padding: 10rem 0;
  width: 60%;
  margin: auto;
}
.restrict_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 2rem 0;
}
.restrict_f2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 1rem 0;
}
.restrict_f3 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.restrict_btncont {
  width: 40%;
  margin: 2rem auto;
}
.premium_main {
  background-color: #070908;
  padding: 8rem;
}
.premium_padding {
  padding: 2rem 0 2rem 7rem;
}

.premium_card {
  box-shadow: 0px 5px 9px rgba(193, 205, 210, 0.25);
  border-radius: 4px;
  background: #fcfcfc;
  border-radius: 4px;
  width: 25%;
  padding: 2rem;
}
.premium_card_cont1 {
  text-align: center;
}
.premium_card_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 1rem 0;
}
.premium_card_f2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.premium_card_f3 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 1rem 0;
}

.premium_card_cnt2 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.premium_card_f4 {
  font-size: 1.28rem;
  line-height: 1.6rem;
  margin: 0.4rem 0;
}
.premium_card_contmain {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.pb_card {
  width: 30%;
  padding: 2rem;
}
.pb_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.pb_f2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.pb_cont {
  display: flex;
  gap: 2rem;
}
.pb_maincont {
  width: 90%;
  margin: auto;
}
.pb_padding {
  padding: 8rem;
}
/* search details */

/* main Financail */
.mainfin_cont {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}
.mainfin_cont1 {
  display: flex;
  gap: 4rem;
  justify-content: space-between;
}
.mainfin_cont2 {
  flex: 1;
}
.mainfin_cont3 {
  flex: 0.5;
}
.mainfin_cont4 {
  flex: 2;
}
.mainfin_cont5 {
  flex: 1.5;
}
.mainfin_cont6 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: space-around;
}

.searchMain_container {
  width: 90%;
  margin: 0 auto;
  padding: 4rem 0;
  display: flex;
}
.searchMain_container--1 {
  width: 50%;
}
.searchMain_container--2 {
  width: 50%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}
.comp_heading {
  font-weight: 800;
  font-size: 3rem;
  line-height: 6rem;
  color: #070908;
}

.comp_details {
  display: flex;
  align-items: center;
}
.comp_link {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 400;
  margin-right: 2rem;
  text-decoration: underline;
}
.comp_social_icon {
  color: #070908;
  font-size: 1.5rem;
  padding: 0.4rem;
  border: 1px solid #070908;
  border-radius: 5px;
}

.updateAndNews {
  margin: 8rem 0;
}
.updateAndNews_container {
  margin: 0 auto;
  width: 80%;
}
.updates_container {
  margin: 4rem 0;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.22);
  height: 45rem;
  width: 100%;
  overflow-y: scroll;
  padding: 1rem;
}
.update_img {
  width: 100%;
}
.updates {
  display: flex;
}
.update_thumb {
  width: 20%;
}
.update_content {
  width: 80%;
  padding: 0 2rem;
}
.updates:not(:last-child) {
  margin-bottom: 2rem;
}
.detailsTabs {
  margin-top: 4rem;
}

.pitch {
  margin: 2rem 0;
}
.pitch {
  margin: 0 auto;
  width: 80%;
}
.heighlights {
  padding: 8rem 0;
}
.heighlight_point {
  display: inline !important;
  border: 1px solid #070908;
  border-radius: 50%;
  padding: 0.2rem 1rem;
  font-size: 1.2rem;
  margin-right: 4rem;
  font-weight: bold;
}
.heighlights_text {
  margin: 4rem 0;
}
.slider {
  width: 70%;
  margin: 0 auto;
}
.slide {
  padding: 0 2rem;
}
.slick-prev:before {
  content: "←";
}
.slick-next:before {
  content: "→";
}
.slick-prev:before,
.slick-next:before {
  color: #4c8df5 !important ;
  font-size: 3.5rem !important;
}
.bottom_status {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 2rem 0;
  display: flex;
  justify-content: end;
  align-items: start;
  gap: 2rem;
  margin: 2rem 0;
}
.input_bottom {
  border: 1px solid #4c8df5;
  padding: 1rem;
  outline: none;
  border-radius: 4px;
}
.bottom_cont {
  text-align: center;
}
.bottom_cont1 {
  margin-right: 4rem;
  display: flex;
  align-items: start;
  gap: 2rem;
}
.input_p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #4f5351;
  margin: 0.2rem 0;
}

.about_container {
  margin: 0 auto;
  width: 80%;
}
.comp_info--title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3rem;
  color: #b2b2b2;
}
.comp_info--data {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3rem;
  color: #070908;
}
.comp_info_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
}
.about {
  margin-top: 8rem;
}
.teams {
  margin: 5rem 0;
}
.teams_primary {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}

.teamsRow--1 {
  width: 50%;
  display: flex;
}
.teamsRow--2 {
  width: 50%;
  display: flex;
}
.team_info {
  margin-left: 2rem;
}
.teams_container {
  display: flex;
  margin-top: 4rem;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  gap: 2rem;
}

.mentors {
  margin: 8rem 0;
}
.mentors_list {
  display: flex;
}
.mentor:not(:last-child) {
  margin-right: 4rem;
}

.flowchart {
  background-color: #070908;
  margin: 8rem 0;
  padding: 4rem 0;
}
.flowchart_container {
  margin: 0 auto;
  width: 90%;
}
.box_container {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  text-align: center;
}
.box_text {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 600;
}
.box {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 1rem 4rem;
  z-index: 10;
  position: relative;
  max-width: 68%;
}
.boxItem {
  display: inline-block;
  position: relative;
  max-width: 50%;
}
.box_subtext {
  color: #b2b2b2;
  font-size: 1.3rem;
}
.box_cont {
  position: absolute;
  text-align: start;
  margin: 0.2rem 0;
}

.boxItem:before,
.boxItem::after {
  content: "";
  width: 100%;
  border-bottom: 5px solid;
  position: absolute;
  top: 50%;
  color: #b2b2b2;
  z-index: 9;
}
:after {
  left: 100%;
}
:before {
  right: 100%;
}
.boxItem:first-of-type:before,
.boxItem:last-of-type:after {
  display: none;
}
/* MainCampCreation-Donations */
.mdf_btncont {
  margin: 1.5rem 0 1.5rem 0;
}
/* MainCampCreation-Perks */
.mdp_cont {
  text-align: center;
}
.mdp_pq {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  margin: 0.5rem 0 0.5rem 0;
}
.mdp_img {
  margin: 3rem 0 0 0;
}
.mdp_btncont {
  margin: 2rem 0 0 0;
}
.mdp_p1 {
  text-align: center;
  color: #198097;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.mdp_cont1 {
  margin-top: 2rem 0;
}
.mdp_cont2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mdp_btncont2 {
  text-align: center;
  margin: 4rem 0;
}
.mdsp_table {
  width: 100%;
  background-color: #f5f5f5;
  padding: 2rem;
  border-collapse: collapse;
  text-align: start;
}
.tablebgcolor {
  background-color: #fff !important;
  margin: 4rem 0;
  overflow-x: scroll;
}
.mdsp_tablehead {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
  /* padding: 2rem; */
  text-align: start;
}
.mdsp_tableheadtr {
  padding: 2rem;
  text-align: start;
  border-bottom: 1px solid #b2b2b2;
  text-align: start;
}
.mdsp_body {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  text-align: start;
}

th {
  padding: 1rem;
  text-align: start;
}
.css-1ygcj2i-MuiTableCell-root {
  font-size: 1.6rem !important;
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  font-size: 1.6rem !important;
}

.css-2ulfj5-MuiTypography-root {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

td {
  padding: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.mdsp_tablecont {
  background-color: #f5f5f5;
  padding: 2rem;
  margin: 2rem 0;
}
.mdsp_icon {
  color: #4c8df5;
  cursor: pointer;
}
/* Modal  */
.mdpd_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  background-color: #fcfcfc;
  box-shadow: #4f5351;
  border-radius: 4px;
}
.mdpd_modalmain {
  padding: 0rem 6rem 2rem 6rem;
}
.mdpd_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  text-align: center;
}
.mdpd_modalbtn {
  text-align: end;
  padding: 0.5rem 1rem 0.5rem 0;
}
.mdpd_close {
  font-size: 2rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #4c8df5;
}
.mdpd_btncont2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5rem;
}
/* Dashboard for campaign Management */
.dashstartcamp_cont {
  padding: 15rem;
  text-align: center;
}
.dashcompsub_contmain {
  padding: 16rem 10rem;
}
.dashcompsub1 {
  width: 50%;
}
.dashcompsubmain {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 6.4rem 10.2rem;
  gap: 11.2rem;
  background-color: #fcfcfc;
  box-shadow: 0px 5px 9px rgba(193, 205, 210, 0.25);
  border-radius: 12px;
}
.dashcompsub_cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.dashcompsub_h2 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.5rem;
  text-align: start;
}
.dashcompsub_p {
  font-weight: 600;
  font-size: 2rem;
  line-height: 6rem;
  text-align: start;
}
.dashcompsub_p1 {
  margin-bottom: 2rem;
}
.dashmaincamp_p1 {
  margin: 1rem 0;
}

/* Investment/donations ----- Pre campaign Page single Page Cont */
.scp_main {
  padding: 12rem;
}
.scp_cont {
  display: flex;
  align-items: flex-start;

  gap: 1rem;
}
.scp_img {
  width: 100%;
  height: 40rem;
}
.scp_cont1 {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 1rem;
  gap: 3rem;
}
.scp_h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
}
.scp_cont2 {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 1rem;
}
.scp_cont3 {
  width: 65%;
}
.share_btn {
  background-color: #4c8df5;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
}
.scp_link {
  text-decoration: underline !important;
  color: #070908;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.scp_con3 {
  display: flex;
  gap: 3rem;
  padding: 1rem 2rem;
}
.scp_btncont {
  color: #4c8df5;
  font-size: 1.5rem;
  padding: 0.4rem;
  border: 1px solid #4c8df5;
  border-radius: 5px;
}
.scp_p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 0 2rem;
}
.scp_p1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: center;
  margin: 8.5rem 0 0 0;
}
.scp_cont4 {
  display: flex;
  text-align: center;
  gap: 2rem;
  padding: 2rem;
}
.scp_p2 {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
}
.scp_cont5 {
  margin-top: 5rem;
}
.scpdetail_cont {
  display: flex;
  margin-top: 1rem;
}

.scpdetail_main {
  padding: 8rem;
}
.scpdetail_h1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.scpdetail_p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin-top: 2rem;
  width: 95%;
}
.scpdetail_cont4 {
  margin-left: 3.8rem;
}
.w60 {
  width: 60%;
}
.lw60 {
  width: 60%;
}
.w40 {
  width: 40%;
}
.scpdetails_detailh1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
.scpdetail_p2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 1rem;
}
.scpdetail_p3 {
  font-weight: 00;
  font-size: 2rem;
  line-height: 3rem;
}
.scpdetail_cont1 {
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
}
.scpdetail_cont2 {
  margin-top: 3rem;
}
.scpdetail_cont3 {
  background: #f5f5f5;
  border-top: 1px solid #fcfcfc;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  text-align: end;
  padding: 2rem 6rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: end;
  margin: 0rem 0 15rem 0;
}
/* add watchlist Modal */
.addwatchlist_modalmain {
  padding: 4rem 9rem;
}
.addwatchlist_h1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: start;
}
.addwatchlist_select {
  margin: 1rem 0;
}
.addwatchmodal_cont {
  margin: 1rem 1rem;
}
.addwatchlist_btncont {
  text-align: center;
  margin: 1rem 0 2rem 0;
}
.deletemodal_btncont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.discussion {
  margin: 8rem 0;
}

.discussion_container {
  width: 80%;
  margin: 0 auto;
}
.dis_primary {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3rem;
}
.dis_secondary {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
::placeholder {
  color: #b2b2b2 !important;
}
.invest_watchlistbtncont {
  text-align: center;
  margin: 15rem 0 0 0;
}
.invest_watchlistp1 {
  text-align: center;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.invest_profiletiles {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4c8df5;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  cursor: pointer;
}
.invest_profiletilesactive {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4c8df5;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  border: 2px solid #4c8df5;
}
.invest_profilecont {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.discussion_content {
  background-color: #f5f5f5;
  margin-top: 4rem;
  padding: 3rem 2rem;
}
.discussion_send {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  background-color: #f5f5f5;
  padding: 0 2rem;
}

.discussion_qcont {
  border: 1px solid #4f5351;
  padding: 2rem;
  margin: 1rem 0;
}
.discussion_content--heading {
  display: flex;
  align-items: center;
}
.dis_date {
  margin-left: auto;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 3rem;
}
.dis_name {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
}
.fa-flag {
  color: #b2b2b2;
  font-size: 2rem;
  padding-left: 2rem;
}
.dis_comment {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 0.2rem;
}
.dis_reply {
  padding-left: 6rem;
  padding-top: 2rem;
}
.comp_financials {
  margin: 8rem 0;
}
.comp_financials_container {
  margin: 0 auto;
  width: 80%;
}
.center {
  text-align: center;
}
.documents {
  display: flex;
}
.document--1 {
  width: 50%;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%);
  border-radius: 7px;
  background-color: #f5f5f5;
  padding: 2rem;
}
.document--2 {
  margin-left: 2rem;
  width: 50%;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%);
  border-radius: 7px;
  background-color: #f5f5f5;
  padding: 2rem;
}
.document_detail {
  display: flex;
  align-items: center;
}
.document_name {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.fa-file-lines {
  color: #4c8df5;
  font-size: 8rem;
  margin-left: auto;
}

.fund_amount {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
}
.fund_use {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.useOfFunds {
  display: flex;
  padding: 1rem 0;
}
.useOfFunds:not(:last-child) {
  border-bottom: 3px solid #f5f5f5;
}
.fund_amount {
  width: 10%;
}
.fund_use {
  width: 90%;

  padding-left: 4rem;
}
/* ------------------------- Section3------MarketPlace-------techOffer */
.mpto_main {
  padding: 14rem;
}
.mpto_h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
}
.mpto_p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  width: 85%;
}
.mpto_cont {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.mpto_cont1 {
  background-color: #f5f5f5;
  padding: 2rem;
  width: 33%;
  margin: 2rem 0;
  box-shadow: 0px 5px 9px rgba(193, 205, 210, 0.25);
  border-radius: 4px;
}
.mpto_cardtitle {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #4c8df5;
  margin: 1rem 0;
}
.mpto_p1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.mpto_cont2 {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.mtpo_cardmain {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 5rem;
}
.mtpo_mr {
  margin: 1rem 0;
  width: 30%;
}
.mtpo_cont1 {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 3rem;
  gap: 2rem;
}
.mtpo_cont2 {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}
.mtpo_p {
  border: 1px solid #070908;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  border-radius: 25rem;
  text-align: center;
  padding: 0.2rem 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.mtoc_main {
  text-align: center;
  padding: 12rem;
}
.mtoc_p {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 3rem 0;
}
.spar_cont {
  text-align: start;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.spar_mr {
  width: 35%;
}
.sr_f1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 0.5rem 0;
}
.sr_f2 {
  font-weight: 700;
}
.sr_f4 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 2rem 0;
}
.sr_cont {
  width: 54%;
  margin: 2rem auto;
}
.sr_mr {
  margin: 2rem 0;
}
.sr_textarea {
  margin: 0 !important;
}
.sr_mr1 {
  margin: 1rem 0 0 0;
}
.srp_btncont {
  text-align: center;
  margin: 2rem 0;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.srp_fw {
  font-weight: 700 !important;
}
.srp_main {
  padding: 13rem;
}
/* --------------------------Marketplace Tech Offer Campaign--------------------- */
.toc_main {
  padding: 13rem 6rem 8rem 6rem;
}
.toc_cont {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}
.w50 {
  width: 50%;
}
.toc_cont1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}
.toc_f1 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #4f5351;
}
.toc_cont2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.toc_cont3 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.toc_f2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.41rem;
  margin: 1rem 0;
}
.toc_f3 {
  font-weight: 600;
}
.toc_btn {
  text-align: end;
}
.toc_cont4 {
  padding: 4rem 0;
  background-color: #070908;
}
.toc_cont5 {
  padding: 8rem 14rem 0 14rem;
}
.toc_ul {
  list-style-type: disc;
  margin: 0 0 0 3rem;
  padding-top: 0 !important ;
}
.toc_mr {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.toc_mr1 {
  margin: 1rem 0 6rem 0;
}
.toc_cont6 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 3rem 0;
  flex-wrap: wrap;
}
.toc_mr3 {
  margin: 8rem 0;
}
.tnp_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.tnp_mr {
  margin: 2rem 0;
}
.tnp_mr1 {
  margin: 1rem 0 1rem 1rem;
}
.tnsr_f1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
/* Market Place Tech need Basic Details */
.tnbd_h1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
.tnbd_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.4rem;
  margin: 0.5rem 0;
}
.tnbd_f2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0 0 0.5rem 0;
}
.tnbd_f3 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 1rem 0;
}
.tnbd_cont {
  margin: 4rem 0;
  width: 75%;
}
.tnbd_cont1 {
  margin: 3rem 0 0rem 0;
}
.tnbu_cont {
  margin: 1rem 0;
}
.tne_mr {
  margin: 2rem 0;
}
.tne_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 2rem 0 4rem 0;
}
.tnf_main {
  width: 75%;
}
/* Scroll to top Image */
.stti {
  text-align: end;
  cursor: pointer;
}
/* investMent/donations Active deals card */
.adaall_main {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
}
.adall_btnmr {
  margin: 2rem 0 4rem 0;
}
/* Investment/condations -----------proceedToPay */
.ptp_cont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}
.ptp_cont1 {
  width: 40%;
}
.ptp_cont2 {
  border: 1px solid #919191;
  border-radius: 1.2rem;
  padding: 2rem;
}
.ptp_h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
}
.ptp_p {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  margin: 2rem 0;
}
.ptp_h2 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.3rem;
  margin: 2rem 0;
}
.ptp_h3 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.ptp_p1 {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
}
.ptp_color {
  color: #4f5351;
  font-weight: 600 !important;
}
.ptp_p2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.ptp_center {
  text-align: center;
}
.ptp_mr {
  margin: 3rem 0;
}
.ptp_cont3 {
  width: 50%;
}
.ptp_cont4 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #919191;
}
.ptp_br {
  border: none;
}
.ptp_fw {
  font-weight: 600;
}
.ptp_mr1 {
  margin: 5rem 0 0 0;
}
.ptp_btncont {
  background-color: #fff;
  border: none;
  box-shadow: none;
  margin: 2rem 0;
}
/* investment and donations ------------InvestCampEnd */
.ice_main {
  padding: 14rem;
  text-align: center;
}
.ice_h1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
  margin: 2rem 0 1rem 0;
}
.ice_p1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.ice_cont {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 2rem 0;
}

.ice_p1 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
}
.ice_cont1 {
  border-bottom: 1px solid #e7e7e7;
  padding: 1rem 0 3rem;

  text-align: start;
}
.ice_cont2 {
  width: 60%;
  margin: auto;
  text-align: start;
}
.ice_p2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.ice_fw {
  font-weight: 400;
}
.ice_mr1 {
  margin: 2rem 0 0 0;
}
.ice_btnimg {
  margin-right: 0.8rem;
}
.ice_mr2 {
  margin: 1rem 0;
}
/* donating campaign -------------donate main perks */
.dmp_stepcont {
  width: 50%;
  margin: 4rem auto;
}
.dmp_f1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
}
.dmp_fw {
  font-weight: 600;
}
.dmp_mr {
  margin: 0;
}
/* invetment/donations donatingPersk card */
.pcard_main {
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  background-color: #fcfcfc;
  padding: 1rem;
}
.dpp_cont {
  display: flex;
  align-items: start;
  gap: 2rem;
  justify-content: center;
  flex-direction: row;
  margin: 2rem 0;
}
.pcard_f2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.pcard_f1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #4f5351;
}
.pcard_fw {
  font-weight: 600;
}
.pcard_color {
  margin-left: 1.5rem;
  color: #4c8df5;
}
.pcard_mr {
  margin: 2rem 0;
}
.p_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.2rem;
}
.p_f2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.p_mr {
  margin: 3rem 0 1rem 0;
  width: 50%;
}
.p_f3 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6ssrem;
}
.p_f4 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
/* Investment/Donations --------Address */
.add_cont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin: 1rem 0;
}
.add_cont1 {
  width: 49%;
}
.add_f1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.add_width {
  width: 30%;
}
.add_btncont {
  padding: 2rem 0;
}
.paym_mr {
  margin: 1rem 1rem;
}
.sdm_mr {
  width: 80%;
  margin: auto;
}
/* Horizontal Flow */
.flow_container {
  background: black;
  padding: 2rem 5rem;
}
.flow_main {
  display: flex;
  align-items: center;
}
.flow_cont {
  background: black;
  width: 20%;
}
.flow_line {
  background: #f5f5f5;
  width: 10%;
  height: 5px;
}
.flow {
  background: #f5f5f5;
  text-align: center;
  /* height:25px; */
  padding: 1rem 1.5rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.2rem;
}
.flow_cont1 {
  background: black;
  height: 10rem;
  color: white;
  width: 100%;
  position: relative;
}
.flow_cont3 {
  background: black;
  height: 10rem;
  color: white;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
}
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  width: 100%;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 3rem;
}
.story_img--image {
  width: 80%;
}
.flow_h1 {
  color: #fcfcfc;
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
  margin-bottom: 5rem;
}
/* xtra calsses */
.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.w90 {
  width: 90%;
}
.w10 {
  width: 10%;
}
.w33 {
  width: 33% !important;
}
.w20 {
  width: 20%;
}
/* Help center Faq */
.p132 {
  padding: 10rem 8rem;
}
.dflex {
  display: flex;
}
.w30 {
  width: 30%;
}
.w70 {
  width: 70%;
}
.faq_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
.faq_f2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.faq_mr {
  margin: 2rem 0;
}
.infq_cont {
  background-color: #f5f5f5;
  padding: 2rem;
}
.faq_f3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.faq_f3.pills:hover {
  cursor: pointer;
  background-color: #444;
  color: #fff;
}
.faq_f600 {
  font-weight: 600 !important;
}
.faq_mr1 {
  margin: 0.5rem 0;
}
.faq_ml {
  margin-left: 3rem;
}
.faq_mrt3 {
  margin-top: 3rem;
}
.alcenter {
  align-items: center;
  justify-content: space-between;
}
.faq_mr3 {
  margin-top: 3rem;
}
.feedback_f1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.textend {
  text-align: end;
}
/* service Provider */
.pt_7 {
  padding-top: 6rem;
}
.bg-black {
  background-color: #070908;
  color: white;
}
.sp_h1 {
  font-weight: 900;
  font-size: 5.6rem;
  line-height: 6.8rem;
}
.p13 {
  padding: 13rem;
}
.gap5 {
  gap: 5rem;
}
.sp_f1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4rem;
}
.p1012 {
  padding: 10rem 12rem;
}
.sp_f2_mr2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 2rem 0;
}
.sp_f16_mr2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 2rem 0;
  height: 12rem;
}
.sp_card {
  background: #f5f5f5;
  box-shadow: 3px 3px 11px rgba(63, 63, 63, 0.15);
  border-radius: 4px;
  padding: 2.4rem;
  width: 25%;
}
.fwrap {
  flex-wrap: wrap;
}
.jc_sb {
  justify-content: space-between;
}
.sp_mrt2 {
  margin-top: 2rem;
}
.sp_f16_mr1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.mb2 {
  margin-bottom: 2rem;
}
/* creative Service */
.cs_h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  margin-bottom: 3rem;
}
.cs_f1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}

.cs_card {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  margin: 2rem 0;
}
.cs_w40 {
  width: 40%;
}
.cs_w60 {
  width: 60%;
  padding: 1rem;
}
.cs_f2 {
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.6rem;
  color: #4f5351;
}
.cs_f3 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.cs_f4 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #4f5351;
}
.cs_fw {
  font-weight: 600;
}
.cs_mr {
  margin: 1rem 0;
}
.cs_wd85 {
  width: 85%;
  margin: auto;
}
.cs_h15 {
  height: 15rem;
}
/* FunraiseStory */
.frs_main {
  background-color: #070908;
  padding: 8rem 0;
}
.frs_cont1 {
  display: flex;
  gap: 2rem;
  width: 80%;
  margin: auto;
}
.eg_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}
.eg_f2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 0.5rem 0;
}

/* tech Assesment */
.ta_main {
  padding: 13rem;
}
.ta_maincont {
  width: 80%;
  margin: auto;
}
.ta_h1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.8rem;
}
.ta_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.ta_f2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0.5rem 0;
}
.ta_mr {
  margin: 2rem 0;
}
.ta_bottombtn {
  background: #f5f5f5;
  border-top: 1px solid #fcfcfc;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  text-align: end;
  padding: 2rem 4rem;
}
.ta_scrollcont {
  text-align: end;
}
/* investment Donation Portfolio */
.portfolio_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;
}
.portfolio_f1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.portfolio_f2 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 6rem;
  color: #4f5351;
}
.portfolio_f3 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #4f5351;
}
.port_mr {
  margin: 2rem 0;
}
.admin_main {
  display: flex;
}
.admin_w20 {
  width: 20%;
  background: black;
}
.admin_cont7 {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.admin_w80 {
  padding: 10rem 2rem;
  width: 80%;
}
.admin_cont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1.5rem;
}
.admin_cont1 {
  box-shadow: 3px 3px 11px rgba(63, 63, 63, 0.15);
  border-radius: 8px;
  padding: 2rem;
  min-width: 20%;
  text-align: end;
}
.admin_f1 {
  font-size: 2rem;
  font-weight: 500;
}
.admin_cont2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin_cont4 {
  box-shadow: 3px 3px 11px rgba(63, 63, 63, 0.15);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 2rem;
  width: 45%;
}
.admintable {
  width: 95%;
  font-size: 1.6rem;
  padding: 1rem;
  border: 1px solid black;
  border-collapse: collapse;
}
.admintbale > tr > td {
  border-bottom: 1px solid black !important;
}
.admin_statgreen {
  background-color: #4c8df5 !important;
  border-radius: 20px;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
}
.admin_statred {
  background-color: red;
  border-radius: 20px;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.admin_statprocess {
  background-color: orange;
  border-radius: 20px;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.editbtn {
  background-color: white;
  border: 1px solid #4c8df5;
  padding: 0.5rem 1rem;
  color: #4c8df5;
  font-size: 2rem;
  border-radius: 4px;
  cursor: pointer;
}
.deletebtn {
  background-color: white;
  border: 1px solid red;
  padding: 0.5rem 1rem;
  color: #4c8df5;
  font-size: 2rem;
  border-radius: 4px;
  cursor: pointer;
}
.admintablde_drop {
  box-shadow: 3px 3px 11px rgba(63, 63, 63, 0.15);
  border-radius: 8px;
}

/* Investment and donation Anylatics */
.iada_f1 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.2rem;
}
.anylatics_contaier {
  padding: 5rem 14rem;
}
.iada_f2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 1rem 0;
}
.iada_cont {
  display: flex;
  margin: 2rem 0;
  gap: 2rem;
}
.iada_cont1 {
  background: #ffffff;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  margin: 1rem 0;
  text-align: center;
  height: 30rem;
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.iada_mr {
  margin: 2rem 0;
}
/* write css above this 👆 */

/* sidebar optimization */

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file > input[type="file"] {
  display: none;
}
.file > label {
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  /* background-color: hsl(0, 0%, 100%); */
  color: hsl(0, 0%, 29%);
  padding: 0.95rem 2.4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  /* background-color: #4c8df5 !important;
  color: white !important; */
}
.upload-icon {
  font-size: 3rem !important;
  color: #4c8df5;
}
.text-justify {
  text-align: justify !important;
  word-break: break-word;
  hyphens: auto;
}

.MuiFormControlLabel-label {
  font-size: 1.6rem !important;
}
.m-t-6-rem {
  margin-top: 6rem !important;
}
.title-hover {
  color: black;
}
.title-hover:hover {
  color: #4c8df5 !important;
}
.heading-bg-color {
  background-color: white;
  color: #7915cf !important;
  border: none;
  padding: 0.5rem;
  text-align: center;
}
/* .heading-bg-color {
  background-color: #565656;
  background: linear-gradient(
    90deg,
    #f546cb,
    #966cf1 33%,
    #4c8df5 66%,
    #25cec4
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  border: none;
  padding: 0.5rem;
  text-align: center;
} */

.m121 {
  margin: 0rem 1rem 2rem 1rem;
}
.lh-base {
  line-height: 2rem !important;
}

.mt-8 {
  margin-top: 1.8rem;
}
.centered {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3rem;
  font-weight: 600;
}
.sub-head {
  font-size: 20px;
  font-weight: 600;
  color: black;
  /* color: #4c8df5 !important; */
}
.w80 {
  width: 80% !important;
}
.login-card-color {
  background-color: #02a5fc !important;
}
.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.w33 {
  width: 33% !;
}

.wh-25 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.wh-8 {
  width: 7rem !important;
  height: 7rem !important;
}

.lightgreen {
  /* background-color: #2dc071; */
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
}
.jigsaw {
  margin-top: -6rem;
  text-align: left;
}
.location {
  font-size: 14px;
  font-weight: 500;
  background-color: #7915cf;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  align-items: center;
  display: flex;
  width: fit-content;
  padding: 2px 8px;
}
.pdcurated {
  font-size: 10px;
  font-weight: 500;
  /* background-color: #c3dbe1; */
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  color: white;
  text-align: center;
  border-radius: 25px 0px;
  align-items: center;
  display: flex;
  width: fit-content;
  padding: 2px 8px;
}
.aboutText {
  position: absolute;
  top: 21%;
  width: 40%;
  left: 4%;
  /* transform: translate(-50%, 50%); */
  color: "black";
  font-size: 32px;
  font-weight: bold;
  /*   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}
/* .MuiCollapse-wrapperInner {
  height: auto !important;
  max-height: 200px !important;
  overflow-y: auto;
} */
.MuiAccordion-region {
  height: auto !important;
  max-height: 300px !important;
  overflow-y: auto;
}
/* media Query---------------------------------------------------------------------------------------------------------- */
@media only screen and (min-width: 1000px) {
  .main_container1 {
    display: flex;
  }
}
@media only screen and (max-width: 1090px) {
  .showCards_container {
    width: 90%;
  }
  .donation_cards_container {
    width: 90%;
  }
  .main_container {
    width: 90%;
  }
  .main_container1 {
    width: 100%;
  }
  .main_text {
    width: 90%;
  }

  .main_banner {
    width: 90%;
  }

  .main_content {
    padding: 0 0 0 4rem;
  }

  .story_container {
    width: 90%;
  }
  .story_header {
    width: 90%;
  }
  .brands_container {
    width: 90%;
  }
  /* .review_container {
    width: 90%;
  } */
  .comment_container {
    width: 90%;
  }

  .faq_container {
    width: 90%;
  }
  .news_container {
    width: 90%;
  }
  .bottomBanner_container {
    width: 90%;
  }
  .threeGrid_container {
    width: 90%;
  }
}
@media only screen and (max-width: 990px) {
  .boxItem:before,
  .boxItem::after {
    width: 0;
  }
  .box_container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 930px) {
  .main_content {
    padding: 0;
  }
}

@media only screen and (max-width: 895px) {
  /* .tile_img {
    width: 11rem;
    height: 10rem;
  } */
  .teamsRow--1 {
    width: 100%;
  }
  .teamsRow--2 {
    width: 100%;
  }

  .teams_container {
    flex-wrap: wrap;
  }
  .navigation_list--link:not(:last-child) {
    margin-right: 1.6rem;
  }

  .footer_content:not(:last-child) {
    margin-right: 1rem;
  }

  .footer_section--1 {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 855px) {
  .story_container {
    flex-wrap: wrap;
  }

  .story_content {
    width: 100%;
  }
  .story_img {
    width: 100%;
  }
  .storyImage {
    margin-left: 0;
    margin-top: 2.5rem;
  }
  .cards_investment {
    width: 45%;
    margin-right: 0;
  }
  /* .card {
    margin-right: 0;
    width: 45%;
  } */

  .cards_content {
    padding: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .story {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.98)
      ),
      url(/src/assets/images/img/home18.PNG);
  }
  .mainImg {
    height: auto;
  }
  .navigation {
    display: none;
  }
  .auth {
    display: none;
  }

  .navigation_menu {
    display: block;
  }

  .main_container {
    display: flex;
    flex-wrap: wrap;
  }
  .main_container1 {
    display: flex;
    flex-wrap: wrap;
  }

  .main_tiles {
    margin-top: 4rem;
    width: 100%;
    order: 2;
  }
  .main_content {
    width: 100%;
    order: 1;
    text-align: center;
  }

  .comments {
    flex-wrap: wrap;
  }

  .comment_user {
    margin-right: 1rem;
  }

  .comment--1 {
    width: 100%;
  }
  .comment--2 {
    margin-top: 1.5rem;
    width: 100%;
  }

  .footer_section--1 {
    flex-wrap: wrap;
  }

  .footer_links {
    order: 1;
    flex-wrap: wrap;
  }
  #footer_main {
    order: 2;
  }
  .footer_content {
    margin-top: 2rem;
    width: 43%;
  }
  .mpto_main {
    padding: 14rem 2rem;
  }

  .ncs_content,
  .btn,
  .fs-18 {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 800px) {
  .ic_cont1 {
    flex-wrap: wrap;
    flex-grow: inherit;
    gap: 2rem;
  }

  .story_container {
    flex-wrap: wrap;
  }

  .story_content {
    width: 100%;
  }
  .story_img {
    width: 100%;
  }
  .storyImage {
    margin-left: 0;
    margin-top: 2.5rem;
  }
  .brands_icons {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .brands_content {
    margin-top: 2rem;
  }

  .news_title {
    margin: 0 2rem;
  }
  .news_thumbnails {
    width: 90%;
    margin: 0 auto;
  }

  .terms {
    flex-wrap: wrap;
  }

  .terms_li {
    margin-top: 1.5rem;
  }
  .terms_li:not(:last-child) {
    margin-right: 2rem;
  }
  .footer_section--3 {
    flex-direction: column;
  }
  .language {
    margin-top: 2rem;
  }

  .thumbnails {
    width: 43%;
  }
  .thumbnails:not(:last-child) {
    margin-right: 1rem;
  }
  .add_width {
    width: 100%;
  }
  .add_cont {
    flex-wrap: wrap;
  }
  .add_cont1 {
    width: 100%;
  }
  .ptp_cont1 {
    width: 100%;
  }
  .ptp_cont {
    flex-direction: column;
  }
  .ptp_cont3 {
    width: 100%;
  }
  .mpto_main {
    padding: 14rem 2rem;
  }
  .ice_main {
    padding: 14rem 2rem;
  }
  .ice_cont2 {
    width: 100%;
  }
  .ice_cont {
    justify-content: center;
  }
}

@media only screen and (max-width: 620px) {
  .land_btn_cont {
    justify-content: center !important;
  }
  .primaryText {
    text-align: center;
  }
  #landing_primary {
    text-align: center;
    font-size: 5rem;
  }
  .counts_container {
    flex-wrap: wrap;
  }
  .counts_content {
    margin-top: 1rem;
  }

  .counts_content:not(:last-child) {
    margin-right: 1rem;
  }
  .main_text {
    width: 100%;
  }

  .cards_investment {
    width: 100%;
    margin-top: 4rem;
  }

  .threeGrid_row {
    flex-wrap: wrap;
  }
  .threeGrid_col {
    width: 100%;
  }
  .threeGrid_col img {
    margin-top: 2rem;
  }

  #change_order {
    order: 2;
  }
  .add_width {
    width: 100%;
  }
  .add_cont {
    flex-wrap: wrap;
  }
  .add_cont1 {
    width: 100%;
  }
  .ptp_cont1 {
    width: 100%;
  }
  .ptp_cont {
    flex-direction: column;
  }
  .ptp_cont3 {
    width: 100%;
  }
  .mpto_main {
    padding: 14rem 2rem;
  }
  .ice_main {
    padding: 14rem 2rem;
  }
  .ice_cont2 {
    width: 100%;
  }
  .ice_cont {
    justify-content: center;
  }
  .admin_main {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  /* .donation_cards_container{
  width: 95%;
 }  */
  .land_btn_cont {
    justify-content: center !important;
  }
  .w-90 {
    width: 90% !important;
  }
  .reoffcard_main {
    width: 100%;
  }
  .reoffcard_p2 {
    font-size: 1.9rem;
    margin: 1.1rem 0;
  }
  .reoff_cont8 {
    font-size: 1.5rem;
  }
  .card_font1 {
    font-size: 1.5rem;
  }
  .story_txtal {
    text-align: center;
  }
  .bottomBanner_container {
    order: 2;
    text-align: center;
  }
  .bottom_img {
    text-align: center;
    width: 100%;
    order: 1;
  }
  .story_p {
    padding: 2rem;
  }
  .ic_cont2 {
    width: 25%;
  }
  .ic_cont2:hover {
    width: 100%;
    background-color: #198097;
    flex-wrap: nowrap;
    /* padding: 0.5rem; */
  }
  .main_img1 {
    width: 70% !important;
  }
  /* .ic_cont1 {
    flex-wrap: wrap;
    flex-grow: inherit;
    gap: 2rem;
  } */

  .admin_main {
    flex-wrap: wrap;
  }
  .pb_card {
    width: 90%;
  }
  .pb_cont {
    flex-wrap: wrap;
  }
  .pb_padding {
    padding: 2rem;
  }
  .premium_padding {
    padding: 2rem;
  }
  .thumbnails {
    margin-top: 4rem;
    width: 100% !important;
  }
  .review {
    padding: 2rem;
  }
  .comments-cont {
    align-items: start;
  }
  /* .main_container{
  width: 95%;
}   
 .main_text{
  width: 100%;
 } 

.main_banner{
 width: 95%;
}   
 


.story_container{
  width: 95%;
}
.story_header{
  width:95%
}
.brands_container{
  width: 95%;
  }  
.review_container{
  width: 95%;
} 
.comment_container{
  width: 95%;
}  

.faq_container{
  width: 95%;
} 
.news_container{
  width: 95%;
} 
.bottomBanner_container{
  width: 95%;
}   */
  .osx_con15 {
    width: 100%;
  }
  .story_content {
    padding: 0 0;
  }
  .side_tile {
    height: 12rem;
  }
  .sideactive {
    height: 12rem;
  }
  .tile_img {
    width: 10rem;
    height: auto;
    margin: 1rem auto;
  }
  .admin_tablebtn {
    margin-top: 0.5rem;
  }
  .admin_cont4 {
    width: 90%;
  }
  .admin_w20 {
    width: 100%;
  }
  .admin_w80 {
    width: 90%;
  }
  .admin_cont {
    flex-wrap: wrap;
  }
  .admin_cont1 {
    min-width: 80%;
  }
  .admin_cont7 {
    flex-wrap: wrap;
  }
  .bottomBanner {
    flex-wrap: wrap;
    padding: 4rem 2rem;
    gap: 2rem;
  }
  .bottom_imgcont {
    width: 100%;
    text-align: center;
  }
  .dropdown {
    position: relative;
    display: none;
  }
  .ta_main {
    padding: 13rem 2rem;
  }
  .ta_maincont {
    width: 90%;
  }
  .dflex {
    flex-direction: column;
  }
  .p132 {
    padding: 13rem 2rem;
  }
  .w70 {
    width: 90%;
    margin: auto;
  }
  .counts_content {
    width: 45%;
  }
  .counts_content {
    margin-top: 4rem;
  }
  /* .counts_container{
  width: 95%;
}  */
  .brands_content {
    width: 43%;
  }
  .w50 {
    width: 100%;
  }
  .footer_text {
    font-size: 1.88rem;
  }
  .copy {
    font-size: 1.66rem;
  }
  /* #footer_main{
  width: 100%;
}  */
  .premium_card {
    width: 90%;
  }
  .premium_main {
    padding: 4rem;
  }
  .refer {
    margin-right: 1rem;
    font-size: 2.5rem;
  }
  .terms_li {
    font-size: 1.66rem;
  }
  .language {
    font-size: 1.66rem;
    margin-top: 4rem;
  }
  .footer_section--2 {
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .content_heading {
    font-size: 2.3rem;
  }
  .footer_li {
    font-size: 1.77rem;
  }
  .footer_content {
    margin-top: 4rem;
    width: 45%;
  }
  .footer {
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .news_title {
    font-size: 2rem;
  }
  .thumbnails {
    width: 100%;
  }
  .bottomBanner_secondary {
    font-size: 2rem;
  }
  .question_container--1 {
    flex-wrap: wrap;
    gap: 2rem;

    justify-content: center;
  }
  .question_container--2 {
    flex-wrap: wrap;
    gap: 2rem;

    justify-content: center;
  }
  .question {
    font-size: 2.5rem;
    width: 100%;
  }
  .question2 {
    margin-left: 0;
    width: 100%;
  }
  .question1 {
    margin-left: 0;
    width: 100%;
  }
  .answer {
    font-size: 1.6rem;
  }
  .brand_name {
    font-size: 1.8rem;
  }
  .tile_secondary {
    font-size: 1.8rem;
  }
  .primaryText {
    font-size: 3.5rem;
    margin-bottom: 50px;
  }
  .storyImage {
    width: 100%;
  }
  .btn_grp {
    flex-direction: column;
  }
  .bottom_btn--1 {
    margin-right: 0;
  }
  .bottom_btn--2 {
    margin-top: 2rem;
  }

  .comment_user img {
    width: 16rem;
  }
  #first_btn {
    margin-top: 0;
  }
  .questions {
    margin-bottom: 2rem;
  }

  .card_primary {
    font-size: 2.5rem;
  }
  .card_secondary {
    font-size: 1.9rem;
  }
  .tag_label {
    font-size: 1.6rem;
  }
  .progressData {
    font-size: 1.5rem;
  }
  .final_data--heading {
    font-size: 1.9rem;
  }
  .final_data--sub {
    font-size: 2rem;
    margin-top: 1rem;
  }
  .seeking {
    font-size: 1.5rem;
  }
  .sub_text {
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
  .secondaryText {
    margin-bottom: 2rem;
  }
  .cards {
    margin-bottom: 6rem;
  }
  #last_no_box {
    margin-top: 5rem;
  }
  .osc {
    width: 100%;
  }
  .search_results {
    justify-content: center;
  }
  /* .content_heading{
  text-align: center;
}
.footer_li{
  text-align: center;
} */
  .premium_card_contmain {
    flex-wrap: wrap;
  }
  .signupoffounders_main {
    max-width: 100%;
  }
  .goal_cont {
    flex-wrap: wrap;
    gap: 2rem;
  }
  .goals_cont2 {
    width: 100%;
    margin-left: 0;
  }
  .step0_h4 {
    width: 100% !important;
  }
  .step0_cont1 {
    max-width: 80%;
    text-align: center;
  }
  .interest_main {
    max-width: 100%;
    padding: 2rem;
  }
  .prequaapp_main {
    padding: 12rem 2rem;
  }
  .prequaapp_cont1 {
    flex-wrap: wrap;
  }
  .prequaapp_card {
    width: 100%;
    padding: 1rem;
  }
  .cfp_main {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sidebar_main {
    width: 100%;
    padding: 2rem 0 0 0;
    overflow: scroll;
  }

  .cfp_wd {
    width: 100%;
  }
  .precampbtncont {
    justify-content: center;
    padding: 2rem 0;
  }
  .prequalend {
    padding: 12rem 0;
  }
  .preQualend {
    width: 100%;
    margin-top: 3rem;
  }
  .nav_cont {
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    gap: 1rem;
  }
  .sidebarNav_item {
    padding: 1rem 2rem !important ;
    text-align: center;
  }

  .active {
    padding: 1rem 2rem !important ;
    text-align: center;
    border-right: none !important;
    border-bottom: 2px solid #4c8df5 !important;
  }
  .sidebar_cont1 {
    padding: 5.5rem 0 0 0;
  }
  .sidebarNav_item:hover {
    border-right: none;
    background-color: none !important;
    padding: 1rem 2rem !important ;
    margin: 0 !important;
  }
  .wtr {
    padding: 2rem 0;
  }
  .wtr2 {
    margin: auto;
  }
  .cfp_cont {
    width: 100%;
  }
  .cfp_wd1 {
    width: 100%;
  }
  .dashstartcamp_cont {
    padding: 15rem 2rem;
  }
  .dashcompsub_contmain {
    padding: 16rem 2rem;
  }
  .dashcompsubmain {
    flex-direction: column;
    padding: 3rem;
  }
  .dashcompsub1 {
    width: 100%;
  }
  .mtpo_cardmain {
    align-items: center;
    justify-content: center;
  }
  .settings_cont {
    max-width: 100%;
  }
  .mdpd_modal {
    width: 100% !important;
  }
  .addwatchlist_modalmain {
    padding: 5rem 2rem;
  }
  .market_cont1 {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
  }

  .refer_cont {
    flex-direction: column;
    padding: 2rem;
  }

  .refer_cont4 {
    width: 90%;
  }
  .refer_btn-grp {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .tnbd_cont {
    width: 100%;
  }
  .tnf_main {
    width: 100%;
  }
  .mtoc_main {
    padding: 12rem 2rem;
  }
  .mpto_main {
    padding: 14rem 2rem;
  }
  .mpto_cont {
    flex-wrap: wrap;
  }
  .mpto_cont1 {
    width: 100%;
    flex-wrap: wrap;
  }
  .mtpo_cont2 {
    flex-wrap: wrap;
  }
  .mtpo_cont1 {
    flex-wrap: wrap;
  }
  .toc_main {
    padding: 13rem 2rem;
  }
  .toc_cont {
    flex-wrap: wrap;
  }
  .toc_cont6 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .toc_cont5 {
    padding: 8rem 4rem 0 4rem;
  }
  .counts_data {
    font-size: 2rem;
  }
  .mpto_h1 {
    font-size: 2.5rem;
    line-height: 4rem;
  }
  .srp_main {
    padding: 13rem 2rem;
  }
  .sr_cont {
    width: 100%;
  }
  .spar_cont {
    flex-wrap: wrap;
  }
  .toc_btn {
    text-align: center;
    margin-top: 1rem;
  }
  .searchMain_container--1 {
    width: 100%;
  }
  .searchMain_container--2 {
    width: 100%;
  }
  .searchMain_container {
    flex-wrap: wrap;
  }
  .mentors_list {
    flex-wrap: wrap;
    justify-content: center;

    gap: 2rem;
  }
  .w90 {
    width: 0;
  }
  .w10 {
    width: 100%;
  }

  .mentors {
    margin: 0;
  }
  .about_container {
    width: 90%;
  }
  .dis_comment {
    font-size: 1.6rem;
  }
  .dis_reply {
    padding-left: 0;
  }
  .dis_name {
    font-size: 1.6rem;
  }
  .mentor:not(:last-child) {
    margin-right: 0rem;
  }
  .comp_info_container {
    flex-direction: column;
    gap: 4rem;
  }
  .p_mr {
    width: 100%;
  }
  .add_width {
    width: 100%;
  }
  .add_cont {
    flex-wrap: wrap;
  }
  .add_cont1 {
    width: 100%;
  }
  .ptp_cont1 {
    width: 100%;
  }
  .ptp_cont {
    flex-direction: column;
  }
  .ptp_cont3 {
    width: 100%;
  }
  .ice_main {
    padding: 14rem 2rem;
  }
  .ice_cont2 {
    width: 100%;
  }
  .ice_cont {
    justify-content: center;
  }
  .filter {
    flex-direction: column;
    gap: 2rem;
  }
  .searchInput {
    width: 100%;
  }
  .searchFilter {
    width: 100%;
  }
  .search {
    padding: 12rem 2rem !important;
  }
  .teams_container {
    flex-direction: column;
    justify-content: center;
  }
  .documents {
    flex-direction: column;
    gap: 2rem;
  }
  .document--2 {
    width: 90%;
    margin-left: 0;
  }
  .document--1 {
    width: 90%;
  }
  .teamsRow--1 {
    width: 100%;
  }
  .teamsRow--2 {
    width: 100%;
  }
  .scp_main {
    padding: 12rem 0;
  }
  .scp_cont {
    flex-wrap: wrap;
  }
  .scp_cont3 {
    width: 100%;
  }
  .scpdetail_main {
    padding: 8rem 2rem;
  }
  .sp_card {
    width: 90%;
  }
  .p13 {
    padding: 5rem 2rem;
  }
  .p1012 {
    padding: 5rem 5rem;
  }
  .cs_card {
    width: 100%;
    flex-wrap: wrap;
  }
  .cs_w40 {
    width: 100%;
  }
  .cs_w60 {
    width: 100%;
  }
  .cs_h15 {
    height: 20rem;
  }
  .ss_main {
    flex-wrap: wrap;
  }
  .ss {
    width: 100%;
  }
  .frs_cont1 {
    flex-wrap: wrap;
  }

  /* Marketplace */
  /* Scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4c8df5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@media only screen and (max-width: 740px) {
  .burger {
    display: block;
  }
  .navigation {
    display: none;
  }
  .auth {
    display: none;
  }
  .navbar_header {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 930px) {
  .footer_list--link:not(:last-child) {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 650px) {
  .social-icons {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 580px) {
  .sidebar_link {
    color: #fff;
    font-size: 2rem;
  }

  .sidebar_link:not(:last-child) {
    margin-bottom: 5rem;
  }
  .sidebar_icon {
    font-size: 2rem;
    color: #fff;
    padding-right: 0.6rem;
  }
  .sidebar_details {
    display: none;
  }
  .refer_cont4 {
    width: 90%;
  }
  .refer_btn-grp {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .ref_cont {
    width: 90%;
  }
}
@media only screen and (max-width: 480px) {
  .login_main {
    min-height: 60vh;
  }
  .brand-logo {
    width: 20%;
  }
  .w60 {
    width: 100% !important;
  }
  .lw60 {
    width: 90% !important;
  }
  .w20 {
    width: 100%;
  }
  .goals_cont2 {
    height: auto !important;
  }
  .ncs_card {
    width: 100% !important;
  }

  .p14 {
    padding: 10rem 2rem;
  }
  .main {
    padding: 12rem 1rem !important;
  }
  .navbar {
    width: 100%;
  }
  .news_title {
    margin: 2rem;
  }
  .news_form {
    align-items: center !important;
    column-gap: 1rem !important;
    margin: 3rem auto !important;
    width: 100% !important;
    justify-content: center !important;
  }
  .navbar_logo {
    font-size: 2rem;
  }
  .refer_cont4 {
    width: 90%;
  }
  .refer_btn-grp {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .ref_cont {
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) {
  .openSidebar {
    width: 40% !important;
  }

  .refer_cont4 {
    width: 90%;
  }
  .refer_btn-grp {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1299px) {
  .cards {
    display: flex;
    flex-wrap: none;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 200px) and (max-width: 1024px) {
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card {
    margin-right: 0;
    width: 100%;
  }
  .about-class {
    display: block !important;
  }
  .w33 {
    width: 100% !important;
  }
  .w50 {
    width: auto !important;
  }
  .btn {
    margin-top: 0 !important;
    font-size: 12px !important;
    padding: 6px !important;
  }
  .active-btn {
    margin-top: 0 !important;
    font-size: 12px !important;
    padding: 6px !important;
  }
  .ncs_card {
    width: 100% !important;
  }
  .ph-csmain {
    flex-wrap: wrap !important;
    row-gap: 6rem !important;
  }
  .w60 {
    width: 100% !important;
  }
  .w30 {
    width: 100%;
  }
  .lw60 {
    width: 90% !important;
  }
  .w40 {
    width: 100%;
  }
  .w20 {
    width: 100%;
  }
  .dflex {
    flex-direction: column;
  }
  .ps-4 {
    padding-left: 0px !important;
  }
  .pe-4 {
    padding-right: 0px !important;
  }
  .image-display-none {
    display: none !important;
  }
  .jigsaw {
    margin-top: 6rem;
    text-align: left;
  }
  .aboutText {
    display: none;
  }
  .profile-image {
    display: none;
  }
  .golesImg {
    display: none !important;
  }
}
.activeTab {
  background-color: #7915cf !important ;
  color: white !important;
}
.Mui-active,
.Mui-completed {
  color: #2c7be7 !important;
}
.MuiStepLabel-label.Mui-active {
  color: #2c7be7 !important;
}

.MuiStepLabel-label {
  color: #b99094 !important;
}
.MuiTab-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize !important;
  border-radius: 40px !important;
}
.MuiTabs-flexContainer {
  width: fit-content;
  display: inline-block;
  border: 2px solid white;
  border-radius: 25px;
}
.MuiInputBase-input {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}
.color-border {
  /* border-bottom: 1px solid white; */
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: bottom left;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
}

.color-border:hover {
  background-image: linear-gradient(
    90deg,
    #f546cb,
    #966cf1 33%,
    #4c8df5 66%,
    #25cec4
  );
}
.icon-background {
  background-color: none;
}
.icon-background:hover {
  opacity: 0.75;
}

.MuiButtonBase-root:hover {
  opacity: 0.7;
}

.about_heading {
  background-image: linear-gradient(
    90deg,
    #f546cb,
    #966cf1 33%,
    #4c8df5 66%,
    #25cec4
  );
  text-align: center;
  width: fit-content;
  background-size: auto 5px;
  background-repeat: no-repeat;
  background-position: bottom left;
  transition: background-image 1s ease-in-out;
  margin: auto;
  padding: 7px;
}

.profile-image {
  width: 20%;
  margin: 25px;
  border-radius: 25px;
  float: left;
}

.profile-text {
  font-size: 17px !important;
  margin: 30px 40px 0 30px;
}

.fs-17 {
  font-size: 17px;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.light-gray {
  background-color: #ebebeb !important;
  color: black !important;
}
.main-text-color {
  background: linear-gradient(
    90deg,
    #f546cb,
    #966cf1 33%,
    #4c8df5 66%,
    #25cec4
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MuiInputLabel-root {
  font-size: 1.4rem !important;
  font-family: "Montserrat", sans-serif !important;
}
.MuiMenuItem-root {
  font-size: 1.4rem !important;
  font-family: "Montserrat", sans-serif !important;
}
.MuiAutocomplete-listbox {
  font-size: 1.4rem !important;
  font-family: "Montserrat", sans-serif !important;
}
.Toastify__toast-body {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}
